<template>
    <div class="animated fadeIn">
        <b-card>
            <!-- <pre>{{ refunds }}</pre> -->
            <template v-slot:header>
                <h5>Daftar Refund Pending</h5>
            </template>
            <div v-if="isLoading">
                <div v-for="i in countLoading" :key="i" class="py-2">
                    <div class="row align-items-center">
                        <div class="col">
                            <PuSkeleton :height="0" :count="3" />
                        </div>
                        <div class="col">
                            <PuSkeleton :height="0" :count="3" />
                        </div>
                        <div class="col">
                            <PuSkeleton :height="0" :count="3" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <template v-if="refunds.length > 0">
                    <div v-for="item in refunds" :key="item.id" :class="item.inventory_files ? 'hasFileTF' : ''"
                        class="card-box-refund">

                        <div class="header-refund mb-2">
                            <div class="d-flex">
                                <span class="mr-1">No. Invoice :</span>
                                <b>{{ item.no_invoice }}</b>
                            </div>
                            <div class="d-flex">
                                <span class="mr-1">Tgl. Invoice :</span>
                                <b>{{ item.tanggal }}</b>
                            </div>
                        </div>
                        <div class="card-refund">
                            <div class="icon-box">
                                <i class="fa fa-clock-o" aria-hidden="true"></i>
                            </div>
                            <div class="body-notif">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="item-key-val">
                                            <div>
                                                <small class="d-block">Nama Supplier</small>
                                                <div>{{ item.shop_name || "-" }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="item-key-val">
                                            <div>
                                                <div>Jumlah Selisih : {{ item.total_item || "0" }} {{ item.satuan }}</div>
                                                <div>Total Dana : Rp <b>{{ $helper.rupiah(item.total_dana || 0) }}</b></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mt-2 mt-md-0 d-flex align-items-end justify-content-end">
                                        <div class="mr-2" v-if="item.inventory_files">
                                            <b class="text-primary">Bukti Transfer</b>
                                            <b-button @click="handleDetailFile(item)" size="sm ml-2" variant="pgiBtn">
                                                <i class="fa fa-eye"></i>
                                            </b-button>
                                        </div>
                                        <div class="mr-2" v-else>
                                            <b class="text-danger"><small>Belum Transfer</small></b>
                                            <b-button v-if="role === 'General Affair'" @click="handleUploadFile(item)"
                                                size="sm ml-2" variant="pgiBtn">
                                                <i class="fa fa-upload"></i>
                                            </b-button>
                                        </div>
                                        <b-button @click="handleDetail(item)" size="sm" variant="pgiBtn">
                                            Detail
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row justify-content-center">
                        <div class="col-md-4 text-center">
                            <span class="d-block mb-2">Tidak Ada Refund Pending</span>
                        </div>
                    </div>
                </template>
            </div>
        </b-card>

        <b-modal id="m-detail" size="lg" hide-footer centered title="Detail Barang">
            <ul v-for="item in detailRefund" class="list-group list-group-flush">
                <li class="list-group-item p-1">
                    <div class="row">
                        <div class="col-md-8">
                            <div>{{ item.nama_barang }}</div>
                            <small>
                                <b>Rp {{ $helper.rupiah(item.harga || "0") }} x {{ item.total_item || "0" }}</b>
                            </small>
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <h6 class="text-end">Rp {{ $helper.rupiah(item.harga * item.total_item || "0") }}</h6>
                        </div>
                    </div>
                </li>
            </ul>
        </b-modal>
        <b-modal id="m-detail-file" size="md" hide-footer centered title="Bukti Transfer Dana">
            <div v-if="detailRefundFile.inventory_files">
                <img class="img-flluid w-100" :src="detailRefundFile.inventory_files.path" alt="image">
            </div>
            <div v-if="role === 'Finance'" class="pt-3 d-flex align-items-end justify-content-between">
                <div>
                    <h5>Rp {{ $helper.rupiah(detailRefundFile.total_dana_rupiah) }}</h5>
                    <small class="text-danger">
                        <b>Pastikan jumlah tranfer sudah sesuai dengan jumlah refund!</b>
                    </small>
                </div>
                <b-button :disabled="isLoading" @click="approveFinanceRefund(detailRefundFile)"
                    variant="pgiBtn">Approve</b-button>
            </div>
        </b-modal>
        <b-modal id="m-upload-file" size="md" hide-footer centered title="Bukti Transfer Dana">
            <div class="row">
                <div class="col-12 mb-3">
                    <label v-if="!uploadFilePreview" for="images" class="choose-image-action">
                        <input @change="handleChangeImages" type="file" accept=".jpg, .png, .pdf" id="images"
                            class="d-none">
                        <i class="fa fa-plus icon-choose"></i>
                    </label>
                    <img class="img-fluid" v-if="uploadFilePreview" :src="uploadFilePreview" alt="">

                </div>
            </div>
            <div class="d-flex justify-content-end">
                <b-button :disabled="isLoading || !uploadFilePreview" @click="handleSubmitBuktiTransfer"
                    variant="pgiBtn">Upload</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axiosClient from "../../config/api";
export default {
    data() {
        return {
            countLoading: [1, 2, 3],
            isLoading: false,
            refunds: [],
            detailRefund: [],
            detailRefundFile: {},
            uploadFilePreview: null,
            formStore: {
                id: null,
                status: null,
                files: null
            },
            role: localStorage.getItem("role")
        }
    },
    methods: {
        handleDetail(item) {
            this.detailRefund = item.detail_refund
            this.$bvModal.show("m-detail");
        },
        handleDetailFile(item) {
            this.detailRefundFile = item
            this.$bvModal.show("m-detail-file");
        },
        handleUploadFile(data) {
            this.formStore = {
                id: data.id,
                status: 2
            }
            this.$bvModal.show("m-upload-file");
        },
        handleChangeImages(e) {
            // this.fileErrorMessage = ''
            const files = e.target.files;
            console.log(files)
            this.createPreviewImage(files[0])
        },
        createPreviewImage(file) {
            let blobURL = URL.createObjectURL(file);
            this.uploadFilePreview = blobURL;
            this.formStore.files = file;
        },
        async getDataRefund() {
            const apiUri = this.role === 'Finance' ? 'transaksi/refund/list-finance' : 'transaksi/refund'
            try {
                this.isLoading = true
                const response = await axiosClient({
                    url: apiUri,
                    method: 'GET',
                    params: {
                        order_by: 'DESC'
                    }
                })
                const datas = response.data.data
                this.refunds = datas.map(item => {
                    return {
                        id: item.id,
                        no_invoice: item.no_invoice,
                        total_dana_rupiah: item.total_dana,
                        total_dana: item.total_dana,
                        total_item: item.total_item,
                        tanggal: item.tanggal,
                        flag: item.flag,
                        status_approval: item.status_approval,
                        shop_name: item.shop_name,
                        bank: item.bank,
                        detail_refund: item.detail_refund,
                        inventory_files: item.inventory_files,
                        satuan: item.satuan || "Pcs",
                        // sp: "=========",
                        ...item
                    }
                })
            } catch (error) {
                console.log(error)
            }
            finally {
                this.isLoading = false
            }
        },
        async handleSubmitBuktiTransfer() {
            try {
                this.isLoading = true
                this.formStore.status = 2;
                let form = new FormData();
                form.append(`files`, this.formStore.files);
                form.append(`status`, this.formStore.status);
                form.append(`id`, this.formStore.id);
                const response = await axiosClient({
                    method: 'POST',
                    url: 'transaksi/refund/store-refund',
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data: form
                })
                this.$bvModal.hide("m-upload-file");
                if (response.status === 200) {
                    this.$helper.toastSucc(this, "Success upload bukti refund")
                    this.getDataRefund()
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                this.isLoading = false
            }
        },
        async approveFinanceRefund(data) {
            try {
                this.isLoading = true
                this.$bvModal.hide("m-detail-file");
                const response = await axiosClient({
                    method: 'POST',
                    url: 'transaksi/refund/store-refund',
                    data: {
                        id: data.id,
                        status: 3
                    }
                })

                if (response.status === 200) {
                    this.$helper.toastSucc(this, "Success Approve refund dana")
                    this.getDataRefund()
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                this.isLoading = false

            }
        }
    },
    mounted() {
        this.getDataRefund()
    }
}
</script>

